import React from 'react';

export interface IconProps {
	width?: number;
	height?: number;
	className?: string;
	style?: React.CSSProperties;
	viewBox?: string;
	pathFill?: string;
}

export * from './generated/ArrowRightFilled';
export * from './generated/IcArrowDown';
export * from './generated/IcArrowRight';
export * from './generated/IcArrowUp';
export * from './generated/IcClose';
export * from './generated/IcTooltip';
