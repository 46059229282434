import { ColorType, PurposeType } from '@pfct/purple';
import { GnbStyleProps } from './index.styled';

export type BgColorKey = GnbStyleProps['bgColorKey'];

type CommonProps = {
	readonly siteMenu: SiteMenu[];
	readonly bgColorKey: BgColorKey;
};

export type PCProps = CommonProps;

export type MobileProps = CommonProps & {
	readonly title: string;
	readonly userMenu: UserMenu[];
};

export type SiteMenu = {
	readonly mobileTitle: string;
	readonly pcTitle: string;
	readonly position: MenuPosition;
	readonly list: SiteMenuItem[];
};

export type SiteMenuItem = {
	title: React.ReactNode;
	readonly showMenu: boolean;
	readonly onClick: () => void;
};

export interface UserMenu {
	readonly title: string;
	readonly buttonOnClick: () => void;
	readonly buttonColorType: ColorType;
	readonly buttonPurpose: PurposeType;
	readonly visible: boolean;
}

export type MenuPosition = 'LEFT' | 'RIGHT' | 'BUTTON';

export enum TitleTemplate {
	CartItemCount = '{{cart_item_count}}',
}
