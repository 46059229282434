export const Layout = (() => {
	// const WIDTH_PC = 1148; // 1024
	const WIDTH_MOBILE = 768;
	return {
		isMobile: () => typeof window !== 'undefined' && window.innerWidth <= WIDTH_MOBILE,
	};
})();

export const Spacing = {
	xxs: 5,
	xs: 10,
	s: 15,
	m: 20,
	l: 25,
	xl: 30,
	xxl: 35,
	xxxl: 40,
};

export const zIndex = {
	floatButton: 5,
	gnb: 10,
	basicLayer: 100,
	modal: 1000,
	loading: 19950824,
};
