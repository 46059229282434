import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import PageUrls from '@peoplefund/constants/page-urls';
import { ChevronLeftMIcon, Headline5, basicColors } from '@pfct/purple';
import { BgColorKey } from '@peoplefund/components/common/Gnb/index.model';
import * as Styled from './index.styled';
import WebviewHandler from '@peoplefund/utils/webview-handler/index.util';
import useEventListener from '@peoplefund/hooks/useEventListener';
function CartFill({
  fillColor
}: {
  fillColor: string;
}) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none" data-sentry-element="svg" data-sentry-component="CartFill" data-sentry-source-file="index.tsx">
			<g id="Group 35550" data-sentry-element="g" data-sentry-source-file="index.tsx">
				<path id="Rectangle 1645" d="M1.50589 6.77713C1.60688 5.87841 2.3669 5.19898 3.27128 5.19894L15.7321 5.19832C16.6367 5.19828 17.3969 5.8779 17.4977 6.77682L18.6752 17.271C18.7933 18.324 17.9694 19.2456 16.9099 19.2457L2.09218 19.2477C1.03233 19.2478 0.208105 18.3259 0.326459 17.2727L1.50589 6.77713Z" fill={fillColor} data-sentry-element="path" data-sentry-source-file="index.tsx" />
				<path id="Ellipse 335 (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M9.50203 2.70721C8.12153 2.70721 7.00222 3.82645 7.00222 5.20733H5.22559C5.22559 2.8455 7.14011 0.930664 9.50203 0.930664C11.864 0.930664 13.7785 2.8455 13.7785 5.20733H12.0018C12.0018 3.82645 10.8825 2.70721 9.50203 2.70721Z" fill={fillColor} data-sentry-element="path" data-sentry-source-file="index.tsx" />
			</g>
		</svg>;
}
function CpleInvestFlowNavigationBar({
  bgColorKey
}: {
  bgColorKey: BgColorKey;
}) {
  const router = useRouter();
  const webviewHandler = WebviewHandler();
  const [isTopScroll, setIsTopScroll] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [canGoBack, setCanGoBack] = useState(false);
  const onClickGoToCartPage = () => {
    router.push(PageUrls.investing.CART);
  };
  const title = useMemo(() => {
    const path = router.pathname;
    if (path.includes('investing/withdraw')) {
      return '출금 금액 입력';
    }
    if (path.includes('investing/register')) {
      return '투자자 등록';
    }
    if (path.includes('investing/onboarding/core-summary')) {
      const [, hash] = router.asPath.split('#');
      if (!hash) {
        return '1 / 5';
      }
      return `${hash.replace(/\D/g, '')} / 5`;
    }
    if (path.includes('/coupon')) {
      return '포인트 및 쿠폰';
    }
    return '';
  }, [router]);
  const showCartIcon = useMemo(() => {
    const path = router.pathname;
    return path.includes(PageUrls.investing.DETAIL);
  }, [router.pathname]);
  const contentColor = useMemo(() => {
    if (!isTopScroll) {
      return basicColors.gray900;
    }
    switch (bgColorKey) {
      case 'basic':
        return basicColors.gray900;
      default:
        return basicColors.white;
    }
  }, [bgColorKey, isTopScroll]);
  const backgroundColor = useMemo(() => {
    if (!isTopScroll) {
      return basicColors.white;
    }
    switch (bgColorKey) {
      case 'transparent':
        return 'transparent';
      default:
        return basicColors.white;
    }
  }, [bgColorKey, isTopScroll]);
  const onClickBackButton = useCallback(() => {
    const path = router.pathname;
    const isWebview = webviewHandler.isWebview;
    if (isWebview && path.includes('investing/onboarding/core-summary')) {
      webviewHandler.bridge.nextAction('close');
    }
    setCanGoBack(false);
    setIsClicked(true);
    if (path.includes('/investing/invest')) {
      if (path.includes('/coupon')) {
        router.replace(`${PageUrls.investing.INVEST}/${router.query.id}`);
      } else if (path.includes('/charge')) {
        router.replace(`${PageUrls.investing.INVEST}/${router.query.id}`);
      } else if (path.includes('/complete')) {
        router.replace(`${PageUrls.investing.DETAIL}/${router.query.id}`);
      } else {
        router.back();
      }
    } else {
      router.back();
    }
  }, [webviewHandler, router]);
  useEffect(() => {
    if (isClicked && !canGoBack) {
      const timeoutId = setTimeout(() => {
        webviewHandler.bridge.nextAction('close');
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [webviewHandler.bridge, canGoBack, isClicked]);
  useEffect(() => {
    const callback = () => {
      setIsTopScroll(window.scrollY === 0);
    };
    window.addEventListener('scroll', callback);
    return () => {
      window.removeEventListener('scroll', callback);
    };
  }, []);
  useEventListener('popstate', () => {
    // fyi. 뒤로갈 수 없으면, 동작 안함

    setCanGoBack(true);
  });
  console.log({
    isTopScroll
  });
  return <Styled.CpleNav backgroundColor={backgroundColor} data-sentry-element="unknown" data-sentry-component="CpleInvestFlowNavigationBar" data-sentry-source-file="index.tsx">
			<Styled.IconWrapper onClick={onClickBackButton} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<ChevronLeftMIcon fillColor={contentColor} data-sentry-element="ChevronLeftMIcon" data-sentry-source-file="index.tsx" />
			</Styled.IconWrapper>
			<Headline5 color={contentColor} data-sentry-element="Headline5" data-sentry-source-file="index.tsx">{title}</Headline5>
			{showCartIcon ? <Styled.IconWrapper onClick={onClickGoToCartPage}>
					<CartFill fillColor={contentColor} />
				</Styled.IconWrapper> : <Styled.EmptyIconWrapper />}
		</Styled.CpleNav>;
}
export default CpleInvestFlowNavigationBar;