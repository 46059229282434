import styled from '@emotion/styled';
import { Larger } from '@peoplefund/utils/media.util';
import { css } from '@emotion/react';
import { basicColors } from '@pfct/purple';
import { zIndex } from '@peoplefund/styles/global.constants';
import { EVENT_BANNER_HEIGHT_MAP } from '@peoplefund/components/index/@components/EventBanner';
export const PC_LAYOUT_MAX_WIDTH = 1080;
export const GNB_HEIGHT = {
  pc: 84,
  mobile: 55
};
export const BgColors = {
  transparent: 'transparent',
  basic: basicColors.white,
  loan: basicColors.lime400,
  invest: basicColors.lime400
} as const;
type PositionTopType = 'PC' | 'MOBILE' | 'NONE';
export interface GnbStyleProps {
  readonly bgColorKey: keyof typeof BgColors;
  readonly positionTopType: PositionTopType;
}
const getTopPixelByPositionTopType = (type: PositionTopType) => {
  switch (type) {
    case 'PC':
      return EVENT_BANNER_HEIGHT_MAP.pc;
    case 'MOBILE':
      return EVENT_BANNER_HEIGHT_MAP.mobile;
    case 'NONE':
      return 0;
  }
};
export const Wrapper = styled.header<GnbStyleProps>`
	z-index: ${zIndex.basicLayer - 1};
	position: sticky;
	top: ${({
  positionTopType
}) => `${getTopPixelByPositionTopType(positionTopType)}px;`};
	left: 0;
	right: 0;
	background: ${({
  bgColorKey
}) => BgColors[bgColorKey]};
	border-bottom: 1px solid
		${({
  bgColorKey
}) => {
  switch (bgColorKey) {
    case 'invest':
      return basicColors.lime400;
    case 'basic':
      return basicColors.gray50;
    default:
      return 'transparent';
  }
}};

	transition: background 0.2s ease;
`;
export const Nav = styled.nav`
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	height: ${GNB_HEIGHT.mobile}px;

	margin: 0 auto;
	box-sizing: border-box;

	${Larger(css`
		max-width: ${PC_LAYOUT_MAX_WIDTH}px;
		height: ${EVENT_BANNER_HEIGHT_MAP.pc}px;
	`)}
`;
export const Logo = styled.img`
	cursor: pointer;

	width: 52px;
	height: 100%;
	padding: 0;
	margin-left: 8px;

	${Larger(css`
		width: 100px;
		height: auto;

		padding: 0;
		margin-left: 0;
		margin-right: 50px;
	`)}
`;
export const VerticalCenter = styled.div`
	display: flex;
	align-items: center;
`;