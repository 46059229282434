import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@peoplefund/store';
import { useRouter } from 'next/router';
import actions from '@peoplefund/actions';
import { getMobileUserMenu, getSiteMenu } from './index.util';
import PcGnb from './component/PC';
import MobileGnb from './component/Mobile';
import PageUrls from '@peoplefund/constants/page-urls';
import { TitleTemplate, UserMenu } from '@peoplefund/components/common/Gnb/index.model';
import * as Styled from './index.styled';
import { GnbStyleProps } from './index.styled';
import useMortgageCartList from '@peoplefund/hooks/useMortgageCartNumber';
import Notification from '@peoplefund/components/common/Notification';
export interface GnbProps {
  readonly isMobile: boolean;
  readonly backgroundColorKey: GnbStyleProps['bgColorKey'];
}
const Gnb: React.FC<GnbProps> = ({
  isMobile,
  backgroundColorKey
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    loggedIn,
    token
  } = useAppSelector(state => state.account.auth);
  const showLoansMypageMenu = useAppSelector(state => state.layout.gnb.showLoansMypageMenu);
  const userName = useAppSelector(state => state.account.userInfo?.userName ?? '');
  const {
    count: cartItemCount
  } = useMortgageCartList({
    initialFetch: loggedIn,
    token
  });
  const [scrollY, setScrollY] = useState(0);
  const positionTopType = router.pathname === '/' && scrollY === 0 ? isMobile ? 'MOBILE' : 'PC' : 'NONE';
  const logoImageUrl = useMemo(() => {
    const getFileName = () => {
      if (isMobile) {
        switch (backgroundColorKey) {
          case 'basic':
            return 'pf-logo-color-m@2x.png';
          default:
            return 'pf-logo-white-m@2x.png';
        }
      } else {
        switch (backgroundColorKey) {
          case 'basic':
            return 'img-pflogo-black@2x.png';
          default:
            return 'img-pflogo-white@2x.png';
        }
      }
    };
    return `${process.env.NEXT_PUBLIC_STATIC_URL}/assets/${getFileName()}`;
  }, [backgroundColorKey, isMobile]);
  const logout = useCallback(() => {
    dispatch(actions.account.signOut.started({}));
  }, [dispatch]);
  const siteMenu = useMemo(() => getSiteMenu(loggedIn, showLoansMypageMenu, userName, isMobile, logout, router).map(menu => {
    const {
      list
    } = menu;
    list.forEach(item => {
      const {
        title
      } = item;
      if (typeof title === 'string') {
        if (title.includes(TitleTemplate.CartItemCount)) {
          const titleWithoutTemplate = title.replace(TitleTemplate.CartItemCount, '');
          item.title = <Styled.VerticalCenter>
									{titleWithoutTemplate}
									{cartItemCount > 0 ? <Notification type="number" value={cartItemCount} /> : null}
								</Styled.VerticalCenter>;
        }
      }
    });
    return menu;
  }), [loggedIn, userName, isMobile, logout, router, cartItemCount, showLoansMypageMenu]);
  const mobileUserMenu: UserMenu[] = useMemo(() => getMobileUserMenu(true, loggedIn, showLoansMypageMenu, router).filter(item => item.visible), [loggedIn, router, showLoansMypageMenu]);
  useEffect(() => {
    const callback = () => {
      setScrollY(window.scrollY);
    };
    addEventListener('scroll', callback);
    return () => {
      removeEventListener('scroll', callback);
    };
  }, []);
  return <Styled.Wrapper data-testid="test#NavWrapper" bgColorKey={backgroundColorKey} positionTopType={positionTopType} data-sentry-element="unknown" data-sentry-component="Gnb" data-sentry-source-file="index.tsx">
			<Styled.Nav data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<Styled.Logo src={logoImageUrl} onClick={() => router.push(PageUrls.HOME)} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
				{isMobile ? <MobileGnb title={loggedIn ? `${userName}님` : '로그인을 해주세요'} siteMenu={siteMenu} userMenu={mobileUserMenu} bgColorKey={backgroundColorKey} /> : <PcGnb siteMenu={siteMenu} bgColorKey={backgroundColorKey} />}
			</Styled.Nav>
		</Styled.Wrapper>;
};
export default Gnb;