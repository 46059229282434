import React from 'react';
import * as Styled from './index.styled';
type commonType = {
  readonly type: 'dot' | 'new' | 'number';
  readonly testId?: string;
};
type DotNotificationProps = commonType & {
  readonly type: 'dot';
};
type NewNotificationProps = commonType & {
  readonly type: 'new';
};
type NumberNotificationProps = commonType & {
  readonly type: 'number';
  readonly value: number;
};
export type NotificationProps = DotNotificationProps | NewNotificationProps | NumberNotificationProps;
const Notification: React.FC<NotificationProps> = props => {
  const {
    type,
    testId
  } = props;
  return <>
			{type === 'dot' && <Styled.Dot data-testid={testId} />}
			{type === 'new' && <Styled.Badge data-testid={testId}>
					<Styled.BadgeContent>N</Styled.BadgeContent>
				</Styled.Badge>}
			{type === 'number' && <>
					{props.value && <Styled.Badge data-testid={testId}>
							<Styled.BadgeContent>{props.value > 9 ? '9+' : props.value}</Styled.BadgeContent>
						</Styled.Badge>}
				</>}
		</>;
};
export default Notification;