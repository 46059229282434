import React from 'react';
import { IconProps } from '../';
export const ArrowRightFilled = React.memo<IconProps>(({
  width = 24,
  height = 24,
  className,
  style,
  pathFill = '#B0BEC5'
}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" style={style} className={className}>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h24v24H0z" />
				<path fill={pathFill} d="M13.884 12l-5.667 5.576a.718.718 0 000 1.03l.185.18a.748.748 0 001.047 0l6.34-6.237a.713.713 0 00.21-.55.71.71 0 00-.21-.548L9.45 5.213a.75.75 0 00-1.047 0l-.185.182a.718.718 0 000 1.03L13.884 12z" />
			</g>
		</svg>);